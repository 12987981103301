import { CompareData, InterestCount, CompareDataEntry } from '../App'

export const sumCompareData = (data: CompareData, areaIndex: number) => {
  return Object.entries(data).reduce(
    (storage: CompareData, [areaLevel, areaData]) => {
      const group = areaLevel.substring(0, areaIndex)
      const d = storage[group] || {
        propertyValue: 0,
        loanSize: 0,
        count: 0,
        interest: {},
      }
      if (!storage[group]) {
        // eslint-disable-next-line no-param-reassign
        storage[group] = d
      }

      const { interest, propertyValue, loanSize, count } = areaData
      d.propertyValue += propertyValue
      d.loanSize += loanSize
      d.count += count

      const addInterests = (sum: InterestCount, add: InterestCount) => {
        Object.entries(add).forEach(([key, c]) => {
          if (sum[key]) {
            // eslint-disable-next-line no-param-reassign
            sum[key] += c
            // eslint-disable-next-line no-param-reassign
          } else sum[key] = c
        })
      }

      if (interest.threeMonth) {
        if (!d.interest.threeMonth) {
          d.interest.threeMonth = {}
        }
        addInterests(d.interest.threeMonth, interest.threeMonth)
      }
      if (interest.oneYear) {
        if (!d.interest.oneYear) {
          d.interest.oneYear = {}
        }
        addInterests(d.interest.oneYear, interest.oneYear)
      }
      if (interest.threeYear) {
        if (!d.interest.threeYear) {
          d.interest.threeYear = {}
        }
        addInterests(d.interest.threeYear, interest.threeYear)
      }
      if (interest.fiveYear) {
        if (!d.interest.fiveYear) {
          d.interest.fiveYear = {}
        }
        addInterests(d.interest.fiveYear, interest.fiveYear)
      }
      return storage
    },
    {}
  )
}

const validateBindingTime = (
  bindingTime: string
): 'threeMonth' | 'oneYear' | 'threeYear' | 'fiveYear' => {
  switch (bindingTime) {
    case 'threeMonth':
      return 'threeMonth'
    case 'oneYear':
      return 'oneYear'
    case 'threeYear':
      return 'threeYear'
    case 'fiveYear':
      return 'fiveYear'
    default:
      throw new Error('Invalid binding time')
  }
}

const sumStartData: CompareDataEntry = {
  interest: {
    threeMonth: {},
    oneYear: {},
    threeYear: {},
    fiveYear: {},
  },
  propertyValue: 0,
  loanSize: 0,
  count: 0,
}

export const sumSwedenAverage = (data: CompareData) => {
  return Object.values(data).reduce((storage, areaData) => {
    const newStorage = { ...storage }
    newStorage.propertyValue += areaData.propertyValue
    newStorage.loanSize += areaData.loanSize
    newStorage.count += areaData.count

    newStorage.interest = Object.entries(areaData.interest).reduce(
      (interestSum, [bindingTime, interestCounts]: [string, InterestCount]) => {
        const accInterestSum = { ...interestSum }
        const validBindingTime = validateBindingTime(bindingTime)

        accInterestSum[validBindingTime] = Object.entries(
          interestCounts
        ).reduce((accInterestCounts, [interest, count]) => {
          if (accInterestCounts) {
            const newAccInterestCounts = { ...accInterestCounts }

            newAccInterestCounts[interest] =
              (newAccInterestCounts[interest] || 0) + count
            return newAccInterestCounts
          }
          return {}
        }, accInterestSum[validBindingTime])
        return accInterestSum
      },
      newStorage.interest
    )
    return newStorage
  }, sumStartData)
}
