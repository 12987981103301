import React from 'react'
import { makeStyles, useTheme, useMediaQuery } from '@material-ui/core'

const useStyles = makeStyles({
  smallAdContainer: {
    display: 'inline-block',
    width: '320px',
    maxWidth: '100%',
  },
  largeAdContainer: {
    display: 'inline-block',
    width: '980px',
    maxWidth: '100%',
  },
  adHeadline: {
    fontWeight: 'bold',
    fontSize: '10px',
    lineHeight: '150%',
    color: '#000000',
    textAlign: 'left',
    margin: '0.5em 0 0.25em',
    textTransform: 'uppercase',
  },
})

interface AdBannerProps {
  show: Boolean
}

export default function AdBanner(props: AdBannerProps) {
  const { show } = props

  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const clickTag = encodeURIComponent(
    'https://hypoteket.com/flytta-bolan-och-byt-bank?utm_source=aftonbladet&utm_medium=rantekartan&utm_campaign=aftonbladet'
  )

  if (!show) return null

  if (isDesktop) {
    return (
      <div className={classes.largeAdContainer}>
        <p className={classes.adHeadline}>Annons</p>
        <iframe
          title="ad"
          src={`/ad/960x120/index.html?clickTag=${clickTag}`}
          width="960"
          height="120"
          style={{ border: 'none', width: '100%' }}
        />
      </div>
    )
  }

  return (
    <div className={classes.smallAdContainer}>
      <p className={classes.adHeadline}>Annons</p>
      <iframe
        title="ad"
        src={`/ad/320x160/index.html?clickTag=${clickTag}`}
        width="320"
        height="160"
        style={{ border: 'none', width: '100%' }}
      />
    </div>
  )
}
