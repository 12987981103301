/* eslint-disable react/prop-types */
import React from 'react'
import { makeStyles, Grid, Box, Typography } from '@material-ui/core'

const useStyle = makeStyles({
  textContaier: { margin: '5px' },
})

interface Props {
  text: string
}

const SearchInput = (data: Props) => {
  const classes = useStyle()
  const { text } = data
  return (
    <Grid item xs={12} className={classes.textContaier}>
      <Box textAlign="left">
        <Typography variant="body2" color="primary">
          {text}
        </Typography>
      </Box>
    </Grid>
  )
}

export default SearchInput
