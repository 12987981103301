import React, { useEffect, useState } from 'react'

import {
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
} from '@material-ui/core'
import InformationTooltip from './InformationTooltip'
import { FocusedCompareData } from '../../App'
import { FormPayload } from '../../form/Form'
import formatAmount from '../../lib/utils'
import { translateBindingTimes } from '../../lib/translate'
import COLORS from '../../assets/colors'

interface DataProps {
  tableData: FocusedCompareData | undefined
  matchesSmAndUp: boolean
  userSentData: FormPayload | undefined
  translatedSearchName: string
}

interface ShortTableData {
  name: string
  compareValue: string
}

interface FullTableData {
  name: string
  userValue: string
  compareValue: string
}

const useStyles = makeStyles({
  root: {
    background: 'white',
    color: COLORS.primary,
  },
  primaryText: {
    color: '#000',
  },
  tableCell: {
    paddingLeft: '5px',
    paddingRight: '5px',
    // display: 'flex',
    // alignItems: 'center',
    '&:first-child': {
      paddingLeft: '8px',
    },
    '&:last-child': {
      paddingRight: '5px',
    },
  },
  secondaryText: {
    color: COLORS.primary,
  },
  table: {
    background: COLORS.backgroundGrey,
  },
  infoIcon: {
    position: 'absolute',
    top: '-5px',
    left: '-5px',
  },
  tableWrapper: {
    position: 'relative',
  },
  tooltipPlacement: {
    marginBottom: '5px',
  },
})

function calculateAvg(datapoint?: number, count?: number) {
  if (!datapoint || !count) return undefined
  return Math.floor(datapoint / count)
}

function calculateAvgInterest(datapoint: any) {
  if (!datapoint) return undefined
  const test = Object.entries(datapoint)

  let formula = 0
  let numberOfPoints = 0
  for (let i = 0; i < test.length; i += 1) {
    numberOfPoints += Number(test[i][1])
    formula += Number(test[i][0]) * Number(test[i][1])
  }
  return (formula / numberOfPoints).toFixed(2)
}

function getInterests(
  interests: string,
  tableData: FocusedCompareData | undefined
) {
  switch (interests) {
    case 'threeMonth':
      return calculateAvgInterest(tableData?.interest.threeMonth)
    case 'oneYear':
      return calculateAvgInterest(tableData?.interest.oneYear)
    case 'threeYear':
      return calculateAvgInterest(tableData?.interest.threeYear)
    case 'fiveYear':
      return calculateAvgInterest(tableData?.interest.fiveYear)
    default:
      return undefined
  }
}

function DataTable(props: DataProps) {
  const classes = useStyles()
  const [tableState, setTableState] = useState<FullTableData[]>([])
  const [shortTableState, setShortTableState] = useState<ShortTableData[]>([])
  const {
    tableData,
    matchesSmAndUp,
    userSentData,
    translatedSearchName,
  } = props

  useEffect(() => {
    if (!userSentData) {
      const threeM = calculateAvgInterest(tableData?.interest.threeMonth) ?? '-'
      const oneY = calculateAvgInterest(tableData?.interest.oneYear) ?? '-'
      const threeY = calculateAvgInterest(tableData?.interest.threeYear) ?? '-'
      const fiveY = calculateAvgInterest(tableData?.interest.fiveYear) ?? '-'
      const avgLoanSize = calculateAvg(tableData?.loanSize, tableData?.count)
      const avgPropertyValue = calculateAvg(
        tableData?.propertyValue,
        tableData?.count
      )
      const formatAvgPropertyValue = avgPropertyValue
        ? `${formatAmount(avgPropertyValue.toString())} kr`
        : '-'
      const formatAvgLoanSize = avgLoanSize
        ? `${formatAmount(avgLoanSize.toString())} kr`
        : '-'

      const values = [
        {
          name: '3 månader',
          compareValue: threeM === '-' ? threeM : `${threeM} %`,
        },
        { name: '1 år', compareValue: oneY === '-' ? oneY : `${oneY} %` },
        { name: '3 år', compareValue: threeY === '-' ? threeY : `${threeY} %` },
        { name: '5 år', compareValue: fiveY === '-' ? fiveY : `${fiveY} %` },
        {
          name: 'Lånstorlek',
          compareValue: formatAvgLoanSize,
        },
        {
          name: 'Bostadsvärde',
          compareValue: formatAvgPropertyValue,
        },
      ]
      setShortTableState(values)
    } else {
      const interest = getInterests(userSentData.bindingTime, tableData) ?? '-'

      const avgPropertyValue = calculateAvg(
        tableData?.propertyValue,
        tableData?.count
      )
      const avgLoanSize = calculateAvg(tableData?.loanSize, tableData?.count)

      const formatAvgLoanSize = avgLoanSize
        ? `${formatAmount(avgLoanSize.toString())} kr`
        : '-'
      const formatAvgPropertyValue = avgPropertyValue
        ? `${formatAmount(avgPropertyValue.toString())} kr`
        : '-'

      const values = [
        {
          name: `Ränta (${translateBindingTimes(userSentData.bindingTime)})`,
          userValue: `${userSentData.interest.toString()} %`,
          compareValue: interest === '-' ? interest : `${interest} %`,
        },
        {
          name: 'Bostadsvärde',
          userValue: `${formatAmount(
            userSentData.propertyValue.toString()
          )} kr`,
          compareValue: formatAvgPropertyValue,
        },
        {
          name: 'Lånestorlek',
          userValue: `${formatAmount(userSentData.loanSize.toString())} kr`,
          compareValue: formatAvgLoanSize,
        },
      ]
      setTableState(values)

      try {
        const script = document.createElement('script')
        script.setAttribute(
          'src',
          'https://secure.adnxs.com/seg?add=24198580&t=1'
        )

        document.head.appendChild(script)
      } catch (err) {
        console.log(err)
      }
    }
  }, [tableData, userSentData])
  return (
    <Grid container>
      <Grid item xs={12} className={classes.tableWrapper}>
        <TableContainer>
          <InformationTooltip
            title="Jämfört med andra som använt tjänsten"
            placement="top-left"
          />
          {userSentData ? (
            <Table
              className={classes.table}
              aria-label="simple table"
              size={matchesSmAndUp ? 'medium' : 'small'}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    className={classes.secondaryText}
                    classes={{ root: classes.tableCell }}
                  >
                    Jämför
                  </TableCell>
                  <TableCell align="left" classes={{ root: classes.tableCell }}>
                    Dina värden
                  </TableCell>
                  <TableCell align="left" classes={{ root: classes.tableCell }}>
                    {translatedSearchName}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableState.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell
                      component="th"
                      scope="row"
                      classes={{ root: classes.tableCell }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      align="left"
                      classes={{ root: classes.tableCell }}
                    >
                      {row.userValue}
                    </TableCell>
                    <TableCell
                      align="left"
                      classes={{ root: classes.tableCell }}
                    >
                      {row.compareValue}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Table
              className={classes.table}
              aria-label="simple table"
              size={matchesSmAndUp ? 'medium' : 'small'}
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left" className={classes.secondaryText}>
                    <></>
                  </TableCell>
                  <TableCell align="left" classes={{ root: classes.tableCell }}>
                    {translatedSearchName}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shortTableState.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell
                      component="th"
                      scope="row"
                      classes={{ root: classes.tableCell }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      align="left"
                      classes={{ root: classes.tableCell }}
                    >
                      {row.compareValue}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default DataTable
