import {
  changeStrokeStyle,
  setGroupStroke,
  removeAllStrokes,
} from './changeStrokeStyle'

const moveElementOnTop = (id: number) => {
  if (document.getElementById(id.toString())) {
    const child = document.getElementById(id.toString())
    return child?.parentNode?.childNodes[
      child.parentNode.childNodes.length - 1
    ].after(child)
  }
  throw new Error(`Could not find element with id: ${id}`)
}

export const zoomIn = (id: string) => {
  const polygon = document?.querySelector(`[id='${parseInt(id, 10)}']`)
  const svg = document.querySelector('#sweSvg')
  const svgGroup = document.querySelector('#sweGroup')

  if (polygon instanceof SVGGraphicsElement) {
    const { x, y, height, width } = polygon.getBBox()

    if (svg && svgGroup) {
      removeAllStrokes()
      // TODO: Make the stroke width scale with bbox size
      setGroupStroke('0.07', 'black')
      changeStrokeStyle('0.3', 'black', polygon)
      moveElementOnTop(parseInt(id, 10))
      svg.setAttribute(
        'viewBox',
        `${x - 10} ${y - 10} ${width + 20} ${height + 20}`
      )
    }
  } else throw new Error('Could not zoom to selection')
}

export const zoomOut = () => {
  const svg = document.querySelector('#sweSvg')
  const svgGroup = document.querySelector('#sweGroup')
  // reset viewbox
  if (svg && svgGroup) {
    svg.setAttribute('viewBox', '0 0 320 660')
    removeAllStrokes()
    setGroupStroke('0.3', 'black')
  } else throw new Error('Could not zoom out')
}
