import { FormPayload } from '../form/Form'

interface Rate {
  // eslint-disable-next-line camelcase
  broker_id?: string
  effectiveInterestRate: string
  id: number
  interestTerm: string
  order: number
  rate: number
  validFrom: string
}

export const formatAmount = (txt: string) => {
  if (txt.length > 3) {
    const val = txt.replace(/ /g, '')
    const fmt = val.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    if (fmt !== txt) return fmt
  }
  return txt
}

export const calculateSavings = (userData: FormPayload, lowestRate: number) => {
  const { interest, loanSize } = userData
  const currentYearlyCost = (interest / 100) * loanSize
  const hypoteketYearlyCost = (lowestRate / 100) * loanSize

  const monthlySavings = (currentYearlyCost - hypoteketYearlyCost) / 12
  const yearlySavings = currentYearlyCost - hypoteketYearlyCost
  const fiveYearsSavings = (currentYearlyCost - hypoteketYearlyCost) * 5

  return { monthlySavings, yearlySavings, fiveYearsSavings }
}

export const isSupported = (getStorage: any) => {
  try {
    const key = '__testing_access_to_local_storage_key__'
    getStorage().setItem(key, key)
    getStorage().removeItem(key)
    return true
  } catch (e) {
    return false
  }
}

export default formatAmount
