/* eslint-disable react/prop-types */
import React from 'react'
import { TextField, makeStyles, Grid } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { County, AreaCode } from '../Map'

const useStyle = makeStyles({
  container: {
    margin: '5px',
  },
})

interface SearchInputProps {
  value: AreaCode | County | null
  options: AreaCode[] | County[]
  handleChange: (e: any, option: AreaCode | County | null) => void
  label: string
  inputValue: string
  setInputValue: (option: string) => void
  setValue: (option: AreaCode | County | null) => void
}

const SearchInput: React.FC<SearchInputProps> = ({
  value,
  options,
  handleChange,
  label,
  inputValue,
  setInputValue,
  setValue,
}) => {
  const classes = useStyle()
  return (
    <Grid item xs={12} className={classes.container}>
      <Autocomplete
        id="select-areaCode"
        size="small"
        options={options}
        value={value}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        getOptionLabel={(option) => option.text}
        getOptionSelected={(option, x) => option.text === x.text}
        onChange={(event: any, newValue: AreaCode | County | null) => {
          setValue(newValue)
          handleChange(event, newValue)
        }}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            label={label}
            variant="outlined"
          />
        )}
      />
    </Grid>
  )
}

export default SearchInput
