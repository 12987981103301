/* eslint import/prefer-default-export: 0 */

export const addSearchToQueue = (payload: any) =>
  fetch(
    'https://zf2ukyai45.execute-api.eu-north-1.amazonaws.com/Prod/add-search-to-queue/',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    }
  )
