import React from 'react'

import {
  makeStyles,
  useTheme,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Button,
  useMediaQuery,
  Box,
  InputAdornment,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { v4 as uuidv4 } from 'uuid'
import data from '../assets/postTowns.json'
import { addSearchToQueue } from '../api/searchData'

import { useInputChange } from './hooks/useInputChange'
import { useErrorState } from './hooks/useErrorState'
import { isSupported } from '../lib/utils'
import COLORS from '../assets/colors'

const useStyles = makeStyles(() => ({
  root: {},
  title: {
    marginTop: '8px',
    marginBottom: '8px',
  },
  formRow: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  buttonGroup: {
    marginTop: '10px',
  },
  wrapper: {
    height: '100%',
  },
  errorNotice: {
    marginTop: '5px',
    color: COLORS.primary,
  },
}))

const bindingTimes = [
  {
    value: 'threeMonth',
    label: '3 månader',
  },
  {
    value: 'oneYear',
    label: '1 år',
  },
  {
    value: 'threeYear',
    label: '3 år',
  },
  {
    value: 'fiveYear',
    label: '5 år',
  },
]

const responsiveLabels = {
  bindingTimeLabel: { short: 'Bindningstid', long: 'Vad är din bindningstid?' },
  interestLabel: { short: 'Ränta', long: 'Vad har du för ränta?' },
  propertyValueLabel: {
    short: 'Bostadens värde',
    long: 'Vad är värdet på din bostad?',
  },
  loanSizeLabel: {
    short: 'Lånstorlek',
    long: 'Hur stort är ditt bolån idag?',
  },
  areaCodeLabel: { short: 'Var bor du?', long: 'Var bor du?' },
}

export interface FormPayload {
  areaCode: string
  bindingTime: string
  interest: number
  loanSize: number
  propertyValue: number
  session: string
}

interface Props {
  toggleShowMap: React.Dispatch<React.SetStateAction<boolean>>
  toggleUserSent: React.Dispatch<React.SetStateAction<FormPayload | undefined>>
}

export interface AreaCode {
  text: string
  value: string
}

const listOfAreaCodes = (): AreaCode[] => {
  return Object.entries(data).map((entry) => ({
    text: entry[0],
    value: entry[1],
  }))
}

function Form(props: Props) {
  const {
    bindingTimeLabel,
    interestLabel,
    propertyValueLabel,
    loanSizeLabel,
    areaCodeLabel,
  } = responsiveLabels

  const [errors, handleErrorState] = useErrorState()
  const [input, handleInputChange] = useInputChange(handleErrorState)

  const classes = useStyles()
  const theme = useTheme()
  const { toggleShowMap, toggleUserSent } = props
  const areaCodeList = listOfAreaCodes()
  const matchesSmAndUp = useMediaQuery(theme.breakpoints.up('sm'))

  const isFormValid = () => {
    const errorValues = Object.values(errors)
    const inputValues = Object.values(input)

    for (let i = 0; i < errorValues.length; i += 1) {
      if (errorValues[i] !== '') return false
    }
    for (let j = 0; j < inputValues.length; j += 1) {
      if (inputValues[j] === '') return false
    }

    return true
  }

  const generatePayload = (): FormPayload => {
    let sessionId = ''
    const json = isSupported(() => localStorage) && window.localStorage.session
    if (json) {
      try {
        sessionId = JSON.parse(json).id
      } catch (err) {
        sessionId = uuidv4()
        const tomorrow = new Date()
        tomorrow.setDate(tomorrow.getDate() + 1)

        const session = {
          id: sessionId,
          expire_at: tomorrow,
        }
        window.localStorage.setItem('session', JSON.stringify(session))
        console.error(err)
        console.log('Failed to get session.', json)
      }
    }

    return {
      areaCode: input.areaCode,
      bindingTime: input.bindingTime,
      interest: Number(input.interest.replace(/,/g, '.').replace(/ /g, '')),
      propertyValue: Number(input.propertyValue.replace(/ /g, '')),
      loanSize: Number(input.loanSize.replace(/ /g, '')),
      session: sessionId,
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()

    if (isFormValid()) {
      const payload = generatePayload()
      addSearchToQueue(payload)
      toggleUserSent(payload)
      toggleShowMap(true)
    } else console.log('errors exists', errors)
  }

  const handleChanges = (e: any) => {
    const {
      target: { value, name },
    } = e
    handleInputChange(name, value)
  }

  const handleAutocompleteChange = (event: any, value: any) => {
    const field = 'areaCode'
    if (value) {
      handleInputChange(field, value.value)
      handleErrorState(field, value.value)
    }
  }
  const handleOnInputChange = (event: any, value: any, reason: any) => {
    if (reason === 'clear') {
      handleInputChange('areaCode', '')
    }
    if (reason === 'input' && value === '') {
      handleInputChange('areaCode', '')
    }
  }

  const skipForm = () => {
    toggleShowMap(true)
  }

  return (
    <Grid item>
      {matchesSmAndUp ? (
        <Typography className={classes.title} variant="h5">
          Steg 1 - Fyll i för att jämföra din ränta
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h5">
          1. Fyll i dina låneuppgifter för att jämföra din ränta
        </Typography>
      )}
      <form
        className={classes.root}
        noValidate
        autoComplete="off"
        onSubmit={(e) => handleSubmit(e)}
      >
        <Grid container>
          <Grid item xs={12} className={classes.formRow}>
            <Grid container spacing={1}>
              <Grid item xs={7}>
                <TextField
                  id="bindingTime"
                  name="bindingTime"
                  error={!!errors.bindingTime}
                  helperText={errors.bindingTime}
                  fullWidth
                  select
                  value={input.bindingTime}
                  onBlur={() =>
                    handleErrorState('bindingTime', input.bindingTime)
                  }
                  onChange={handleChanges}
                  label={
                    matchesSmAndUp
                      ? bindingTimeLabel.long
                      : bindingTimeLabel.short
                  }
                >
                  {bindingTimes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  id="interest"
                  name="interest"
                  error={!!errors.interest}
                  helperText={errors.interest}
                  fullWidth
                  onChange={handleChanges}
                  onKeyDown={(e) =>
                    e.key === 'Enter' ? e?.preventDefault() : ''
                  }
                  label={
                    matchesSmAndUp ? interestLabel.long : interestLabel.short
                  }
                  inputProps={{
                    inputMode: 'decimal',
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.formRow}>
            <TextField
              id="propertyValue"
              name="propertyValue"
              value={input.propertyValue}
              error={!!errors.propertyValue}
              helperText={errors.propertyValue}
              fullWidth
              onChange={handleChanges}
              onKeyDown={(e) => (e.key === 'Enter' ? e?.preventDefault() : '')}
              label={
                matchesSmAndUp
                  ? propertyValueLabel.long
                  : propertyValueLabel.short
              }
              inputProps={{
                inputMode: 'numeric',
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">kr</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.formRow}>
            <TextField
              id="loanSize"
              name="loanSize"
              value={input.loanSize}
              error={!!errors.loanSize}
              helperText={errors.loanSize}
              fullWidth
              onChange={handleChanges}
              onKeyDown={(e) => (e.key === 'Enter' ? e?.preventDefault() : '')}
              label={matchesSmAndUp ? loanSizeLabel.long : loanSizeLabel.short}
              inputProps={{
                inputMode: 'numeric',
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">kr</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.formRow}>
            <Autocomplete
              id="select-areaCode"
              size="small"
              options={areaCodeList}
              onChange={handleAutocompleteChange}
              onInputChange={handleOnInputChange}
              getOptionLabel={(option: any) => option.text}
              getOptionSelected={(option, x) => option.text === x.text}
              renderInput={(params: any) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  error={!!errors.areaCode}
                  helperText={errors.areaCode}
                  onKeyDown={(e) =>
                    e.key === 'Enter' ? e?.preventDefault() : ''
                  }
                  onBlur={() => handleErrorState('areaCode', input.areaCode)}
                  label={
                    matchesSmAndUp ? areaCodeLabel.long : areaCodeLabel.short
                  }
                />
              )}
            />
          </Grid>
        </Grid>
        {isFormValid() ? (
          <></>
        ) : (
          <Grid container>
            <Grid item xs={12} className={classes.errorNotice}>
              <Typography variant="caption">
                <Box textAlign="right">Vänligen fyll i samtliga fält.</Box>
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid
          className={classes.buttonGroup}
          justify="flex-end"
          spacing={2}
          container
        >
          <Grid item>
            <Button variant="contained" color="secondary" onClick={skipForm}>
              Hoppa över
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" type="submit" color="primary">
              Jämför
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  )
}

export default Form
