import React, { useState, useEffect } from 'react'
import {
  makeStyles,
  useMediaQuery,
  useTheme,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Button,
  Typography,
  Tooltip,
} from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutlineOutlined'
import { getHypoteketDynamicInterestRates } from '../../api/hypoteket'
import { formatAmount, calculateSavings } from '../../lib/utils'
import HypoteketLogo from '../../assets/hypoteket.svg'
import COLORS from '../../assets/colors'
import { FormPayload } from '../../form/Form'
import { isLtvAbove75 } from '../utils/isLtvAbove75'

interface DataProps {
  userSentData: FormPayload | undefined
  setIsShowingAdTable: Function
}

interface DynamicInterestRate {
  // eslint-disable-next-line camelcase
  base_rate_id: number
  code: string
  codeEffectiveInterestRate: number
  codeInterestRate: number
  effectiveInterestRate: string
  interestRate: number
}

const useStyles = makeStyles({
  adHeadline: {
    fontWeight: 'bold',
    fontSize: '10px',
    lineHeight: '150%',
    color: '#000000',
    textAlign: 'left',
    margin: '0.5em 0 0.25em',
    textTransform: 'uppercase',
  },
  root: {
    background: '#F0FCFA',
    color: COLORS.primary,
    padding: '5px 10px',
    border: '1px solid #000000',
  },
  primaryText: {
    color: '#000',
  },
  tableCell: {
    paddingLeft: '5px',
    paddingRight: '5px',
    '&:first-child': {
      paddingLeft: '8px',
    },
    '&:last-child': {
      paddingRight: '5px',
    },
  },
  secondaryText: {
    color: COLORS.primary,
  },
  infoIcon: {
    position: 'absolute',
    top: '-5px',
    left: '-5px',
  },
  tableWrapper: {
    position: 'relative',
  },
  tooltipPlacement: {
    marginBottom: '5px',
  },
  logo: {
    width: '160px',
    maxWidth: '100%',
    padding: '10px 0 5px',
  },
  logoDesktop: {
    width: '160px',
    maxWidth: '100%',
    padding: '0',
  },
  end: {
    margin: '10px 0 0',
    display: 'flex',
    flexDirection: 'column-reverse',
    width: '100%',
    alignItems: 'center',
  },
  endDesktop: {
    margin: '10px 0 5px',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  moneySavedText: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '125%',
    color: '#CB3D39',
  },
  moneySavedTextLarge: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '21px',
    lineHeight: '125%',
    color: '#CB3D39',
  },
  top: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontFamily: 'Arial',
    color: 'black',
    fontSize: '10px',
    lineHeight: 1,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  helpIcon: {
    fill: '#37767d',
  },
  textAlignLeft: {
    textAlign: 'left',
  },
  textAlignRight: {
    textAlign: 'right',
  },
})

export default function AdTable(props: DataProps) {
  const { userSentData, setIsShowingAdTable } = props
  const classes = useStyles()
  const [open, setTooltip] = useState(false)
  const [title, setTooltipTitle] = useState('')

  const [betterOffer, setBetterOffer] = useState(false)
  const [savingsPerMonth, setSavingsPerMonth] = useState('')
  const [savingsPerYear, setSavingsPerYear] = useState('')
  const [savingsPerFiveYears, setSavingsPerFiveYears] = useState('')
  const [dynamicInterestRate, setDynamicInterestRate] = useState<
    DynamicInterestRate
  >()

  const theme = useTheme()
  const isDesktopScreen = useMediaQuery(theme.breakpoints.up('md'))

  useEffect(() => {
    async function fetchDynamicInterestRate() {
      if (userSentData) {
        try {
          const response = await getHypoteketDynamicInterestRates(userSentData)
          const body = await response.json()

          if (response.ok) {
            setDynamicInterestRate(body)
          } else {
            throw body
          }
        } catch (error) {
          console.error(error)
        }
      }
    }

    if (!dynamicInterestRate) {
      fetchDynamicInterestRate()
    }
  }, [dynamicInterestRate, userSentData])

  useEffect(() => {
    setIsShowingAdTable(betterOffer)
  }, [betterOffer, setIsShowingAdTable])

  useEffect(() => {
    if (dynamicInterestRate) {
      const lowestRate = dynamicInterestRate?.codeEffectiveInterestRate

      if (!lowestRate) {
        throw new Error('Failed to retrieve the lowest rate')
      }

      if (userSentData && lowestRate) {
        const {
          monthlySavings,
          yearlySavings,
          fiveYearsSavings,
        } = calculateSavings(userSentData, lowestRate)

        if (monthlySavings > 100) {
          setBetterOffer(true)
          setSavingsPerYear(
            `${formatAmount(Math.floor(yearlySavings).toString())} kr`
          )
          setSavingsPerFiveYears(
            `${formatAmount(Math.floor(fiveYearsSavings).toString())} kr`
          )
          setSavingsPerMonth(
            `${formatAmount(Math.floor(monthlySavings).toString())} kr`
          )
        }

        setTooltipTitle(`
          Besparingsexemplet är baserat på de uppgifter du har fyllt i och Hypotekets aktuella ränta.
          Exemplet är förenklat och tar inte hänsyn till amorteringskrav, ränteavdrag eller andra förutsättningar som kan påverka den enskilda boendekostnaden och skall därför endast betraktas som en indikation.
        `)
      }
    }
  }, [userSentData, dynamicInterestRate])

  if (!betterOffer) return null

  if (isLtvAbove75(userSentData)) return null

  return (
    <>
      <p className={classes.adHeadline}>Annons</p>
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.tableWrapper}>
          <Typography
            variant="overline"
            color="textSecondary"
            className={classes.top}
          >
            <Tooltip
              disableHoverListener
              disableTouchListener
              onMouseEnter={() => setTooltip(true)}
              onMouseLeave={() => setTooltip(false)}
              title={title}
              arrow
              open={open}
              placement="top-start"
            >
              <HelpOutlineIcon
                className={classes.helpIcon}
                fontSize="small"
                onClick={() => setTooltip(!open)}
              />
            </Tooltip>
          </Typography>
          <TableContainer>
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="right"
                    colSpan={2}
                    classes={{ root: classes.tableCell }}
                  >
                    Per mån.
                  </TableCell>
                  <TableCell
                    align="right"
                    classes={{ root: classes.tableCell }}
                  >
                    Per år
                  </TableCell>
                  {isDesktopScreen ? (
                    <TableCell
                      align="right"
                      classes={{ root: classes.tableCell }}
                    >
                      På 5 år
                    </TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left" classes={{ root: classes.tableCell }}>
                    Du kan spara
                  </TableCell>
                  <TableCell
                    align="right"
                    className={
                      isDesktopScreen
                        ? classes.moneySavedTextLarge
                        : classes.moneySavedText
                    }
                    classes={{ root: classes.tableCell }}
                  >
                    {savingsPerMonth}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={
                      isDesktopScreen
                        ? classes.moneySavedTextLarge
                        : classes.moneySavedText
                    }
                    classes={{ root: classes.tableCell }}
                  >
                    {savingsPerYear}
                  </TableCell>
                  {isDesktopScreen ? (
                    <TableCell
                      align="right"
                      className={
                        isDesktopScreen
                          ? classes.moneySavedTextLarge
                          : classes.moneySavedText
                      }
                      classes={{ root: classes.tableCell }}
                    >
                      {savingsPerFiveYears}
                    </TableCell>
                  ) : null}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <div className={isDesktopScreen ? classes.endDesktop : classes.end}>
          <img
            className={isDesktopScreen ? classes.logoDesktop : classes.logo}
            src={HypoteketLogo}
            alt="Hypoteket logo"
          />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
            href="https://hypoteket.com/flytta-bolan-och-byt-bank?utm_source=aftonbladet&utm_medium=rantekartan&utm_campaign=aftonbladet"
            target="_blank"
          >
            Ansök utan att binda dig
          </Button>
        </div>
      </Grid>
    </>
  )
}
