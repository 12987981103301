import React from 'react'
import { List, ListItem, makeStyles, Typography } from '@material-ui/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import COLORS from '../../assets/colors'

const useStyles = makeStyles({
  root: {
    background: 'white',
    color: COLORS.primary,
  },
  list: {
    padding: '0px',
  },
  listItem: {
    padding: '0px',
    height: '30px',
    margin: '0px',
    marginLeft: '5px',
  },
  primaryText: {
    color: '#000',
  },
  secondaryText: {
    color: COLORS.primary,
  },
  legendText: {
    paddingLeft: '5px',
  },
})

const MapLegend = () => {
  const classes = useStyles()

  return (
    <List classes={{ padding: classes.list }}>
      <ListItem dense className={classes.listItem}>
        <FiberManualRecordIcon style={{ color: COLORS.high }} />
        <Typography variant="caption"> {'> 1.8 %'}</Typography>
      </ListItem>
      <ListItem dense className={classes.listItem}>
        <FiberManualRecordIcon style={{ color: COLORS.highMid }} />
        <Typography variant="caption">≤ 1.8 %</Typography>
      </ListItem>
      <ListItem dense className={classes.listItem}>
        <FiberManualRecordIcon style={{ color: COLORS.mid }} />
        <Typography variant="caption">≤ 1.6 %</Typography>
      </ListItem>
      <ListItem dense className={classes.listItem}>
        <FiberManualRecordIcon style={{ color: COLORS.lowMid }} />
        <Typography variant="caption">≤ 1.4 %</Typography>
      </ListItem>
      <ListItem dense className={classes.listItem}>
        <FiberManualRecordIcon style={{ color: COLORS.low }} />
        <Typography variant="caption">{'< 1.2 %'}</Typography>
      </ListItem>
    </List>
  )
}

export default MapLegend
