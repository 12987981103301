/* eslint-disable import/prefer-default-export */
import { FormPayload } from '../../form/Form'

export const isLtvAbove75 = (userSentData: FormPayload | undefined): boolean => {
  if (!userSentData) return false

  const { loanSize, propertyValue } = userSentData

  return loanSize > propertyValue * 0.75
}
