const COLORS = {
  // low: '#7FB069',
  // lowMid: '#FEEF77',
  // mid: '#F1A75B',
  // highMid: '#FF7F44',
  // high: '#db504a',
  // green version
  // low: '#00B49C',
  // lowMid: '#00957D',
  // mid: '#00765E',
  // highMid: '#00583E',
  // high: '#00391F',
  // AB-red version
  low: '#EE9699',
  lowMid: '#E56166',
  mid: '#DD2A30',
  highMid: '#AF1D21',
  high: '#7B1417',
  primary: '#dd2a30',
  noData: '#cecece',
  backgroundGrey: '#f5f5f5',
}
export default COLORS
