import React from 'react'
import { makeStyles, Tooltip } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import COLORS from '../../assets/colors'

const useStyles = makeStyles({
  infoIconTopLeft: {
    position: 'absolute',
    top: '-5px',
    left: '-5px',
  },
  infoIconCenter: {
    marginLeft: '5px',
    textAlign: 'center',
  },
  tooltipPlacement: {
    marginBottom: '5px',
  },
})

interface Props {
  title: string
  placement: 'center' | 'top-left'
}

function InformationTooltip(props: Props) {
  const classes = useStyles()
  const [open, setTooltip] = React.useState(false)
  const { title, placement } = props

  const placementClass =
    placement === 'top-left' ? classes.infoIconTopLeft : classes.infoIconCenter

  return (
    <Tooltip
      arrow
      title={title}
      classes={{ tooltipPlacementTop: classes.tooltipPlacement }}
      disableHoverListener
      disableTouchListener
      onMouseEnter={() => setTooltip(true)}
      onMouseLeave={() => setTooltip(false)}
      open={open}
      placement="top-start"
    >
      <InfoIcon
        style={{ color: COLORS.primary }}
        className={placementClass}
        fontSize="small"
        onClick={() => setTooltip(!open)}
      />
    </Tooltip>
  )
}

export default InformationTooltip
