import React from 'react'
import { Grid, useMediaQuery } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { ReactComponent as SvgMap } from '../assets/SWE-Map_Kommuner2007_divided.svg'
import Form, { FormPayload } from './Form'

const useStyles = makeStyles(() => ({
  root: {},
  formContainer: {
    padding: '8px',
    height: 500,
  },
  map: {
    background: '#f3f3f3',
    height: 500,
  },
  wrapper: {
    minHeight: 500,
  },
  formWrapper: {
    height: '100%',
    paddingLeft: 15,
    paddingRight: 15,
  },
}))

interface Props {
  toggleShowMap: React.Dispatch<React.SetStateAction<boolean>>
  toggleUserSent: React.Dispatch<React.SetStateAction<FormPayload | undefined>>
}

function FormContainer(props: Props) {
  const classes = useStyles()
  const { toggleShowMap, toggleUserSent } = props
  const theme = useTheme()
  const matchesSmAndUp = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Grid container justify="center" className={classes.wrapper}>
      <Grid item sm={8} md={6} xs={12} className={classes.formContainer}>
        <Grid className={classes.formWrapper} container alignItems="center">
          <Form toggleShowMap={toggleShowMap} toggleUserSent={toggleUserSent} />
        </Grid>
      </Grid>
      {matchesSmAndUp ? (
        <Grid item sm={4} md={6} className={classes.map}>
          <SvgMap className={classes.map} />
        </Grid>
      ) : null}
    </Grid>
  )
}
export default FormContainer
