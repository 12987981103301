const countyList = [
  { text: 'Stockholms län', value: '01' },
  { text: 'Uppsala län', value: '03' },
  { text: 'Södermanlands län', value: '04' },
  { text: 'Östergötlands län', value: '05' },
  { text: 'Jönköpings län', value: '06' },
  { text: 'Kronoberg län', value: '07' },
  { text: 'Kalmar län', value: '08' },
  { text: 'Gotlands län', value: '09' },
  { text: 'Blekinge län', value: '10' },
  { text: 'Skåne län', value: '12' },
  { text: 'Hallands län', value: '13' },
  { text: 'Västra Götalands län', value: '14' },
  { text: 'Värmlands län', value: '17' },
  { text: 'Örebro län', value: '18' },
  { text: 'Västermanlands län', value: '19' },
  { text: 'Dalarnas län', value: '20' },
  { text: 'Gävleborgs län', value: '21' },
  { text: 'Västernorrlands län', value: '22' },
  { text: 'Jämtlands län', value: '23' },
  { text: 'Västerbottens län', value: '24' },
  { text: 'Norrbottens län', value: '25' },
]

export default countyList
