import React, { useEffect, useState } from 'react'
import { Grid, useMediaQuery, Typography, Box } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import countyList from '../assets/countyList'
import { ReactComponent as SvgMap } from '../assets/SWE-Map_Kommuner2007_divided.svg'
// import { municipality as mapData } from '../assets/test.json'
import postTowns from '../assets/postTowns.json'
import addFillColor from './methods/addFillColor'
import DataTable from './components/DataTable'
import AdTable from './components/AdTable'
import ScbDataTable from './components/ScbDataTable'
import MapLegendContainer from './map-table/MapLegendContainer'
import SearchInput from './components/SearchInput'
import SelectBindingTime from './components/SelectBindingTime'
import ToggleButtons from './components/ToggleButtons'
import NoDataFoundText from './components/NoDataFoundText'
import {
  translateFilterButtonText,
  translateCodeToName,
} from '../lib/translate'
import { zoomOut, zoomIn } from './methods/zoomMethods'

import { CompareData, CompareDataEntry, FocusedCompareData } from '../App'
import { FormPayload } from '../form/Form'

import './Map.css'
import COLORS from '../assets/colors'

const useStyles = makeStyles({
  root: {},
  searchContainer: {
    padding: '5px',
  },
  tableContainer: {
    marginTop: '5px',
  },
  searchInputContainer: {
    marginTop: '5px',
    marginBottom: '5px',
  },
  headerContainer: {
    marginTop: '5px',
    marginBottom: '5px',
  },
  headerText: {
    marginBottom: '0px',
    marginTop: '0px',
  },
  smallSize: {
    width: '100%',
  },
  largeSize: {
    maxHeight: '700px',
    width: '100%',
  },
  largeSizeMap: {
    height: '500px',
    width: '100%',
  },
  smallMapSize: {
    height: '230px',
    width: '100%',
  },
  scbDataContainer: {
    marginBottom: '5px',
    marginTop: '5px',
  },
  svgContainer: {
    background: COLORS.backgroundGrey,
    height: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
})

export interface AreaCode {
  text: string
  value: string
}

export interface County {
  text: string
  value: string
}

const listOfAreaCodes = (): AreaCode[] => {
  return Object.entries(postTowns).map((entry) => ({
    text: entry[0],
    value: entry[1],
  }))
}

const noDataFoundTexts = {
  noAreaCodeUserData:
    'Det finns inte tillräckligt med räntedata för din postort. Jämför med din kommun: ',
  noMunicipalityUserData:
    'Det finns inte tillräckligt med räntedata för din kommun. Jämför med ',
  noAreaCodeData:
    'Det finns inte tillräckligt med inrapporterade räntor för postorten. Jämför med kommun: ',
  noMunicipalityData:
    'Det finns inte tillräckligt med inrapporterade räntor i kommunen. Jämför med ',
}

interface MapProps {
  swedenData: CompareDataEntry | undefined
  municipalityData: CompareData | undefined
  countyData: CompareData | undefined
  postCodeData: CompareData | undefined
  userSentData: FormPayload | undefined
  setIsShowingAdTable: Function
}

function Map(props: MapProps) {
  const {
    countyData,
    municipalityData,
    postCodeData,
    userSentData,
    swedenData,
    setIsShowingAdTable,
  } = props
  const theme = useTheme()
  const matchesSmAndUp = useMediaQuery(theme.breakpoints.up('sm'))
  const matchesMdAndUp = useMediaQuery(theme.breakpoints.up('md'))

  const classes = useStyles()
  const areaCodeList = listOfAreaCodes()

  const [municipalityCode, setMunicipalityCode] = useState('')
  const [countyCode, setCountyCode] = useState('')

  const [inputValue, setInputValue] = useState('')

  const [selectedBindingTime, selectBindingTime] = useState('threeMonth')

  const [noDataText, setNoDataText] = useState('')

  const [searchName, setSearchName] = useState('Postort')
  const [searchData, setSearchData] = useState<FocusedCompareData | undefined>()

  const [selectedFilter, toggleSelectedFilter] = useState('areaCode')
  const [alignment, setAlignment] = React.useState<string | null>('center')
  const [value, setValue] = useState<AreaCode | County | null>(null)

  const isAreaCode = selectedFilter === 'areaCode'
  const options = isAreaCode ? areaCodeList : countyList

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    const target = event.target as HTMLTextAreaElement
    const translatedFilter = translateFilterButtonText(
      target.innerText.toLowerCase()
    )
    toggleSelectedFilter(translatedFilter)
    setAlignment(newAlignment)
  }

  const handleChange = isAreaCode
    ? (e: any, option: AreaCode | null) => {
        setMunicipalityCode(option?.value ?? '')
      }
    : (e: any, option: any) => {
        setCountyCode(option?.value ?? '')
      }

  const label = isAreaCode ? 'Postort' : 'Län'

  const resetSearch = (filter: string) => {
    const svgGroup = document.querySelector('#sweGroup')
    zoomOut()
    if (selectedFilter === 'county' && countyData) {
      addFillColor(svgGroup, countyData, selectedBindingTime, 'counties')
    } else if (selectedFilter !== 'county' && municipalityData) {
      addFillColor(
        svgGroup,
        municipalityData,
        selectedBindingTime,
        'municipalities'
      )
    }
    setNoDataText('')
    setMunicipalityCode('')
    setCountyCode('')
    setInputValue('')
    setValue(null)

    if (filter !== 'sweden') setSearchData(undefined)
    if (filter === selectedFilter) {
      return
    }
    setSearchName('Postort')
  }

  const setStateForData = (
    compareData: [string, CompareDataEntry],
    type: string,
    text: string
  ) => {
    // set the data for the table
    setSearchData(compareData[1])
    const translatedCode = translateCodeToName(compareData[0], type)
    setSearchName(translatedCode)
    if (text === '') setNoDataText('')
    else setNoDataText(text + translateCodeToName(compareData[0], type))
  }

  const usersInterestDataExists = (
    data: [string, CompareDataEntry] | undefined,
    usersData: FormPayload
  ) => {
    if (!data) return false
    const [, areaData] = data

    return Object.entries(areaData.interest).find(
      (p) => p[0] === usersData.bindingTime
    )
  }

  const allInterestDataExists = (data: [string, CompareDataEntry]) => {
    const [, areaData] = data
    return Object.keys(areaData.interest).length > 0 ?? false
  }

  const handleSearchCountyCode = () => {
    const svgGroup = document.querySelector('#sweGroup')
    if (countyData) {
      addFillColor(svgGroup, countyData, selectedBindingTime, 'counties')
      zoomIn(countyCode)

      const cData = Object.entries(countyData).find(([key]) => {
        return key === countyCode
      })

      if (cData) setStateForData(cData, 'county', '')
    }
  }

  const handleSearchMunicipalityCode = () => {
    if (municipalityData && postCodeData && countyData) {
      const postTownData = Object.entries(postCodeData).find(([key]) => {
        return key === municipalityCode
      })
      const mData = Object.entries(municipalityData).find(([key]) => {
        return key === municipalityCode.substring(0, 4)
      })

      const cData = Object.entries(countyData).find(([key]) => {
        return key === municipalityCode.substring(0, 2)
      })

      if (mData && cData) {
        zoomIn(municipalityCode.substring(0, 4))

        if (userSentData) {
          if (
            postTownData &&
            usersInterestDataExists(postTownData, userSentData)
          ) {
            setStateForData(postTownData, 'areaCode', '')
          } else if (usersInterestDataExists(mData, userSentData)) {
            setStateForData(
              mData,
              'municipality',
              noDataFoundTexts.noAreaCodeUserData
            )
          } else {
            setStateForData(
              cData,
              'county',
              noDataFoundTexts.noMunicipalityUserData
            )
          }
        } else if (!userSentData) {
          if (postTownData && allInterestDataExists(postTownData)) {
            setStateForData(postTownData, 'areaCode', '')
          } else if (allInterestDataExists(mData)) {
            setStateForData(
              mData,
              'municipality',
              noDataFoundTexts.noAreaCodeData
            )
          } else {
            setStateForData(
              cData,
              'county',
              noDataFoundTexts.noMunicipalityData
            )
          }
        }
      }
    }
  }

  useEffect(() => {
    if (userSentData) {
      setMunicipalityCode(userSentData.areaCode)
      selectBindingTime(userSentData.bindingTime)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const svgGroup = document.querySelector('#sweGroup')
    if (svgGroup && municipalityData && countyData) {
      if (selectedFilter === 'sweden') {
        addFillColor(
          svgGroup,
          municipalityData,
          selectedBindingTime,
          'municipalities'
        )
        setSearchData(swedenData)
        setSearchName('Sverige')
      } else if (selectedFilter === 'county') {
        addFillColor(svgGroup, countyData, selectedBindingTime, 'counties')
        setSearchName('Län')
      } else {
        addFillColor(
          svgGroup,
          municipalityData,
          selectedBindingTime,
          'municipalities'
        )
        setSearchName('Postort')
      }
    }
  }, [
    countyData,
    matchesSmAndUp,
    municipalityData,
    selectedBindingTime,
    selectedFilter,
    swedenData,
    userSentData,
  ])

  useEffect(() => {
    if (municipalityCode) {
      if (userSentData && !value) {
        const preSelected = {
          text: translateCodeToName(userSentData.areaCode, 'areaCode'),
          value: userSentData.areaCode,
        }
        setValue(preSelected)
      }
      handleSearchMunicipalityCode()
    }
    if (countyCode) {
      handleSearchCountyCode()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [municipalityCode, countyCode])

  return (
    <Grid
      className={matchesSmAndUp ? classes.largeSize : classes.smallSize}
      container
      direction={matchesSmAndUp ? 'row-reverse' : 'row'}
    >
      <Grid item xs={12} sm={6}>
        <div className="legend-container">
          <MapLegendContainer />
        </div>
        <div className={classes.svgContainer}>
          <SvgMap
            className={
              matchesSmAndUp ? classes.largeSizeMap : classes.smallMapSize
            }
          />
          <SelectBindingTime
            selectBindingTime={selectBindingTime}
            initialValue={userSentData?.bindingTime}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid
          container
          justify="center"
          direction="row"
          className={matchesSmAndUp ? classes.searchContainer : ''}
        >
          <Grid item sm={12} className={classes.headerContainer}>
            <Typography variant="h5" className={classes.headerText}>
              {matchesMdAndUp ? 'Steg 2 - ' : '2. '} Välj vad du vill jämföra
              med
            </Typography>
          </Grid>
          <ToggleButtons
            alignment={alignment}
            handleChange={handleAlignment}
            selectedFilter={selectedFilter}
            handleClick={resetSearch}
          />
          {selectedFilter !== 'sweden' && (
            <SearchInput
              value={value}
              options={options}
              handleChange={handleChange}
              label={label}
              inputValue={inputValue}
              setInputValue={setInputValue}
              setValue={setValue}
            />
          )}
          {selectedFilter === 'areaCode' && noDataText !== '' && (
            <NoDataFoundText text={noDataText} />
          )}
          <Grid item xs={12} className={classes.tableContainer}>
            {selectedFilter !== 'sweden' ? (
              <DataTable
                tableData={searchData}
                matchesSmAndUp={matchesSmAndUp}
                userSentData={userSentData}
                translatedSearchName={searchName}
              />
            ) : (
              <>
                <Box className={classes.scbDataContainer}>
                  <ScbDataTable matchesSmAndUp={matchesSmAndUp} />
                </Box>
                <DataTable
                  tableData={searchData}
                  matchesSmAndUp={matchesSmAndUp}
                  userSentData={userSentData}
                  translatedSearchName={searchName}
                />
              </>
            )}
          </Grid>
          <Grid item xs={12} className={classes.tableContainer}>
            <AdTable
              userSentData={userSentData}
              setIsShowingAdTable={setIsShowingAdTable}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Map
