/* eslint-disable import/prefer-default-export */
import { useState } from 'react'
import formatAmount from '../../lib/utils'

export const useInputChange = (
  errorHandler: (field: string, value: string) => void
): [{ [key: string]: string }, (field: string, value: string) => void] => {
  const [input, setInput] = useState({
    bindingTime: '',
    interest: '',
    propertyValue: '',
    loanSize: '',
    areaCode: '',
  })

  const handleInputChange = (field: string, value: string) => {
    errorHandler(field, value)
    return setInput({
      ...input,
      [field]:
        field === 'propertyValue' || field === 'loanSize'
          ? formatAmount(value)
          : value,
    })
  }

  return [input, handleInputChange]
}
