export const removeAllStrokes = () => {
  const allGroups = document.querySelectorAll('g')
  const allPolygons = document.querySelectorAll('polygon')
  for (let i = 0; i < allGroups.length; i += 1) {
    if (allGroups[i].getAttribute('id') !== 'sweGroup') {
      allGroups[i].removeAttribute('stroke')
      allGroups[i].removeAttribute('stroke-width')
    }
  }
  for (let i = 0; i < allPolygons.length; i += 1) {
    allPolygons[i].removeAttribute('stroke')
    allPolygons[i].removeAttribute('stroke-width')
  }
}

export const setGroupStroke = (width: string, color: string) => {
  const svgGroup = document.querySelector('#sweGroup')
  if (svgGroup) {
    svgGroup.setAttribute('stroke', color)
    svgGroup.setAttribute('stroke-width', width)
  }
}

export const changeStrokeStyle = (
  width: string,
  color: string,
  svgObject: SVGElement
) => {
  removeAllStrokes()
  svgObject.setAttribute('stroke', color)
  svgObject.setAttribute('stroke-width', width)
}
