import React, { useEffect, useState } from 'react'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import { v4 as uuidv4 } from 'uuid'
import pym from 'pym.js'

import localCompareData from './assets/localCompareData.json'
import COLORS from './assets/colors'

import FormContainer from './form/FormContainer'
import { FormPayload } from './form/Form'

import Map from './map-view/Map'
import AdBanner from './components/AdBanner'

import { sumCompareData, sumSwedenAverage } from './lib/parseCompareData'
import { filterCompareData } from './lib/filterCompareData'
import { isSupported } from './lib/utils'
import { getData } from './api/getData'

import './App.css'

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: COLORS.primary,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#ffffff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: COLORS.primary,
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  overrides: {},
})

export interface CompareData {
  [key: string]: CompareDataEntry
}

export interface CompareDataEntry {
  interest: InterestCompareData
  propertyValue: number
  loanSize: number
  count: number
}

export interface InterestCompareData {
  threeMonth?: InterestCount
  oneYear?: InterestCount
  threeYear?: InterestCount
  fiveYear?: InterestCount
}

export interface InterestCount {
  [key: string]: number
}

export interface FocusedCompareData {
  interest: InterestCompareData
  propertyValue: number
  loanSize: number
  count: number
}

export interface Rate {
  // eslint-disable-next-line camelcase
  broker_id?: string
  effectiveInterestRate: string
  id: number
  interestTerm: string
  order: number
  rate: number
  validFrom: string
}

function App() {
  const [postCodeData, setPostTownData] = useState<CompareData | undefined>()
  const [municipalityData, setMunicipalityData] = useState<
    CompareData | undefined
  >()
  const [countyData, setCountyData] = useState<CompareData | undefined>()
  const [swedenData, setSwedenData] = useState<CompareDataEntry | undefined>()
  const [isShowingAdTable, setIsShowingAdTable] = useState<boolean | false>()

  useEffect(() => {
    pym.Child({
      renderCallback: () => {
        pym.sendHeight()
      },
      polling: 500,
    })

    async function fetchData() {
      const res = await getData()
      const resultJSON = await res.json()
      if (resultJSON) {
        const perArea = resultJSON.perArea ? resultJSON.perArea : resultJSON
        const municipalitySum = sumCompareData(perArea, 4)
        const countySum = sumCompareData(perArea, 2)
        const swedenSum = sumSwedenAverage(perArea)

        setPostTownData(filterCompareData(perArea))
        setMunicipalityData(filterCompareData(municipalitySum))
        setCountyData(filterCompareData(countySum))
        setSwedenData(swedenSum)
      }
    }

    if (process.env.NODE_ENV === 'development') {
      console.log('Development env. Using static user data file')
      const municipalitySum = sumCompareData(localCompareData, 4)
      const countySum = sumCompareData(localCompareData, 2)
      const swedenSum = sumSwedenAverage(localCompareData)

      setPostTownData(filterCompareData(localCompareData))
      setMunicipalityData(filterCompareData(municipalitySum))
      setCountyData(filterCompareData(countySum))
      setSwedenData(swedenSum)
    } else {
      console.log('Production env. Fetching user data...')
      fetchData()
    }

    if (isSupported(() => localStorage)) {
      if (!window.localStorage.session) {
        const sessionId = uuidv4()
        const tomorrow = new Date()
        tomorrow.setDate(tomorrow.getDate() + 1)

        const session = {
          id: sessionId,
          expire_at: tomorrow,
        }
        window.localStorage.setItem('session', JSON.stringify(session))
      } else {
        const timeNow = new Date()
        const sessionExpireAt = JSON.parse(window.localStorage.session)
          .expire_at

        if (sessionExpireAt < timeNow) {
          const sessionId = uuidv4()
          window.localStorage.removeItem('session')

          const tomorrow = new Date()
          tomorrow.setDate(tomorrow.getDate() + 1)

          const session = {
            id: sessionId,
            expire_at: tomorrow,
          }
          window.localStorage.setItem('session', JSON.stringify(session))
        }
      }
    }
  }, [])

  const [showMap, toggleShowMap] = useState(false)
  const [userSentData, toggleUserSent] = useState<FormPayload | undefined>()

  return (
    <MuiThemeProvider theme={theme}>
      <div className="app">
        {showMap ? (
          <Map
            swedenData={swedenData}
            municipalityData={municipalityData}
            countyData={countyData}
            postCodeData={postCodeData}
            userSentData={userSentData}
            setIsShowingAdTable={setIsShowingAdTable}
          />
        ) : (
          <FormContainer
            toggleShowMap={toggleShowMap}
            toggleUserSent={toggleUserSent}
          />
        )}

        <AdBanner show={!isShowingAdTable} />
      </div>
    </MuiThemeProvider>
  )
}

export default App
