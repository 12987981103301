import municipalityCodes from '../assets/municipality-codes.json'
import postTownCodes from '../assets/postTowns.json'
import countyList from '../assets/countyList'

const translateFilterButtonText = (filter: string) => {
  switch (filter) {
    case 'sverige':
      return 'sweden'
    case 'län':
      return 'county'
    case 'postort':
      return 'areaCode'
    default:
      return 'sweden'
  }
}

const translateFilter = (filter: string) => {
  switch (filter) {
    case 'sweden':
      return 'Sverige'
    case 'county':
      return 'Län'
    case 'areaCode':
      return 'Postort'
    default:
      return 'Sverige'
  }
}

const translateBindingTimes = (bindingTime: string) => {
  switch (bindingTime) {
    case 'threeMonth':
      return '3 mån'
    case 'oneYear':
      return '1 år'
    case 'threeYear':
      return '3 år'
    case 'fiveYear':
      return '5 år'
    default:
      return ''
  }
}

const translatePeriods = (period: string): string => {
  switch (period) {
    case '1.1.1':
      return '3 mån'
    case '1.1.2':
      return '1 år'
    case '1.1.3.1.2':
      return '3 år'
    case '1.1.3.2':
      return '5 år'
    default:
      throw new Error('Missing translation')
  }
}

const translateCodeToName = (code: string, type: string): string => {
  if (type === 'municipality') {
    const codes = Object.entries(municipalityCodes)
    const result = codes.find((m) => m[0] === code)
    return result?.[1] ?? 'Kommun'
  }
  if (type === 'areaCode') {
    const codes = Object.entries(postTownCodes)
    const result = codes.find((p) => p[1] === code)
    return result?.[0] ?? 'Postort'
  }
  if (type === 'county') {
    const county = countyList.filter((c) => c.value === code)[0]
    return county.text
    // [ { text: 'Stockholms län', value: '01' },...]
  }
  return ''
}

export {
  translateFilterButtonText,
  translateFilter,
  translateBindingTimes,
  translatePeriods,
  translateCodeToName,
}
