/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import {
  Select,
  MenuItem,
  makeStyles,
  Typography,
  Grid,
} from '@material-ui/core'
import COLORS from '../../assets/colors'

const useStyle = makeStyles({
  container: {
    borderBottom: `1.5px solid ${COLORS.primary}`,
  },
  selectBindTime: {
    marginLeft: '5px',
    marginRight: '5px',

    '&:before,&:after': {
      border: 'none !important',
    },
  },
  icon: {
    color: COLORS.primary,
  },
  test: {
    '&:focus': {
      background: 'none !important',
      border: 'none !important',
    },
  },
  selectLabel: { marginLeft: '5px', marginRight: '2px' },
  selectContainer: { marginLeft: '2px' },
  // selectFormControl: {
  //   padding: '5px !important',
  // },
})

interface Props {
  selectBindingTime: React.Dispatch<React.SetStateAction<string>>
  initialValue: string | undefined
}

const SelectBindingTime = (props: Props) => {
  const { selectBindingTime, initialValue } = props
  const [bindingTime, setBindingTime] = useState(initialValue || 'threeMonth')

  const classes = useStyle()
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setBindingTime(event.target.value as string)
  }

  useEffect(() => {
    selectBindingTime(bindingTime)
  }, [bindingTime, selectBindingTime])

  return (
    <Grid container alignItems="center" className={classes.container}>
      <Grid item className={classes.selectLabel}>
        <Typography variant="body2">
          Kartan visar data för bindningstiden:
        </Typography>
      </Grid>
      <Grid item className={classes.selectContainer}>
        {/* <FormControl
          variant="outlined"
          size="small"
        > */}
        <Select
          value={bindingTime}
          onChange={handleChange}
          classes={{
            icon: classes.icon,
            select: classes.test,
            // outlined: classes.selectFormControl,
          }}
          displayEmpty
          className={classes.selectBindTime}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value="threeMonth">
            <Typography variant="subtitle2">3 mån</Typography>
          </MenuItem>
          <MenuItem value="oneYear">
            <Typography variant="subtitle2">1 år</Typography>
          </MenuItem>
          <MenuItem value="threeYear">
            <Typography variant="subtitle2">3 år</Typography>
          </MenuItem>
          <MenuItem value="fiveYear">
            <Typography variant="subtitle2">5 år</Typography>
          </MenuItem>
        </Select>
        {/* </FormControl> */}
      </Grid>
    </Grid>
  )
}

export default SelectBindingTime
