/* eslint-disable react/prop-types */
import React from 'react'
import { makeStyles, Grid } from '@material-ui/core'

import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import COLORS from '../../assets/colors'

const useStyles = makeStyles({
  root: {
    background: 'white',
    color: COLORS.primary,
    '&:hover': {
      background: '#efefef',
    },
    paddingLeft: '14px',
    paddingRight: '14px',
  },
  toggleButtonGroup: {
    '&:not(:first-child)': {
      marginLeft: '0px',
    },
  },
  toggleButton: {
    color: 'white !important',
    background: `${COLORS.primary} !important`,

    '&:hover': {
      background: '#af1d21 !important',
    },
  },
  container: {
    margin: '5px',
  },
})

interface ToggleButtonsProps {
  alignment: string | null
  handleChange: (
    e: React.MouseEvent<HTMLElement>,
    option: string | null
  ) => void
  selectedFilter: string
  handleClick: (filter: string) => void
}

const SearchInput: React.FC<ToggleButtonsProps> = ({
  alignment,
  handleChange,
  selectedFilter,
  handleClick,
}) => {
  const classes = useStyles()
  return (
    <Grid item sm={12} className={classes.container}>
      <ToggleButtonGroup
        value={alignment}
        size="small"
        exclusive
        classes={{ grouped: classes.toggleButtonGroup }}
        onChange={handleChange}
        aria-label="text alignment"
      >
        <ToggleButton
          value="right"
          aria-label="right aligned"
          selected={selectedFilter === 'areaCode'}
          classes={{
            root: classes.root,
            selected: classes.toggleButton,
          }}
          onClick={() => handleClick('areaCode')}
        >
          Postort
        </ToggleButton>
        <ToggleButton
          value="center"
          aria-label="centered"
          selected={selectedFilter === 'county'}
          classes={{
            root: classes.root,
            selected: classes.toggleButton,
          }}
          onClick={() => handleClick('county')}
        >
          Län
        </ToggleButton>
        <ToggleButton
          value="left"
          selected={selectedFilter === 'sweden'}
          aria-label="left aligned"
          classes={{
            root: classes.root,
            selected: classes.toggleButton,
          }}
          onClick={() => handleClick('sweden')}
        >
          Hela Sverige
        </ToggleButton>
      </ToggleButtonGroup>
    </Grid>
  )
}

export default SearchInput
