/* eslint-disable import/prefer-default-export */
import { useState } from 'react'
import formatAmount from '../../lib/utils'

const MAX_INTEREST = 10
const MIN_INTEREST = 0.5

const MAX_PROPERTY_VALUE = 100000000
const MIN_PROPERTY_VALUE = 500000
const MAX_LOAN_AMOUNT = 20000000
const MIN_LOAN_AMOUNT = 100000

const MAX_PROPERTY_VALUE_S = formatAmount(MAX_PROPERTY_VALUE.toString())
const MIN_PROPERTY_VALUE_S = formatAmount(MIN_PROPERTY_VALUE.toString())
const MAX_LOAN_AMOUNT_S = formatAmount(MAX_LOAN_AMOUNT.toString())
const MIN_LOAN_AMOUNT_S = formatAmount(MIN_LOAN_AMOUNT.toString())

const validateInterest = (interest: string) => {
  const interestAsNumber = Number(interest.replace(/,/g, '.').replace(/ /g, ''))
  if (Number.isNaN(interestAsNumber)) {
    return 'Vänligen ange endast siffror'
  }
  if (interestAsNumber > MAX_INTEREST || interestAsNumber < MIN_INTEREST) {
    return `Ange din ränta mellan ${MIN_INTEREST} och ${MAX_INTEREST} %`
  }
  return ''
}

const validateAreaCode = (areaCode: string) => {
  if (areaCode === '') {
    return 'Vänligen välj var du bor'
  }
  return ''
}

const validateBindingTime = (bindingTime: string) => {
  if (bindingTime === '') {
    return 'Vänligen välj en bindningstid'
  }
  return ''
}

const validatePropertyValue = (propertyValue: string) => {
  const propertyValueAsNumber = Number(
    propertyValue.replace(/,/g, '.').replace(/ /g, '')
  )
  if (Number.isNaN(propertyValueAsNumber)) {
    return 'Vänligen ange endast siffror'
  }
  if (propertyValue.includes('.') || propertyValue.includes(',')) {
    return 'Vänligen ange bostadens värde i heltal, t.ex. 2 000 000'
  }
  if (
    propertyValueAsNumber > MAX_PROPERTY_VALUE ||
    propertyValueAsNumber < MIN_PROPERTY_VALUE
  ) {
    return `Ange ett värde mellan ${MIN_PROPERTY_VALUE_S} och ${MAX_PROPERTY_VALUE_S}`
  }

  return ''
}

const validateLoanSize = (loanSize: string) => {
  const loanSizeAsNumber = Number(loanSize.replace(/,/g, '.').replace(/ /g, ''))
  if (Number.isNaN(loanSizeAsNumber)) {
    return 'Vänligen ange endast siffror'
  }
  if (loanSize.includes('.') || loanSize.includes(',')) {
    return 'Vänligen ange lånet i heltal, t.ex. 500 000'
  }
  if (
    loanSizeAsNumber > MAX_LOAN_AMOUNT ||
    loanSizeAsNumber < MIN_LOAN_AMOUNT
  ) {
    return `Ange ett värde mellan ${MIN_LOAN_AMOUNT_S} och ${MAX_LOAN_AMOUNT_S}`
  }

  return ''
}

const validate = (field: string, value: string) => {
  switch (field) {
    case 'bindingTime':
      return validateBindingTime(value)
    case 'interest':
      return validateInterest(value)
    case 'propertyValue':
      return validatePropertyValue(value)
    case 'loanSize':
      return validateLoanSize(value)
    case 'areaCode':
      return validateAreaCode(value)
    default:
      throw new Error(`Missing validation for field: ${field}`)
  }
}

export const useErrorState = (): [
  { [key: string]: string },
  (field: string, value: string) => void
] => {
  const [errors, setError] = useState({
    bindingTime: '',
    interest: '',
    propertyValue: '',
    loanSize: '',
    areaCode: '',
  })
  const handleInputChange = (field: string, value: string) =>
    setError({
      ...errors,
      [field]: validate(field, value),
    })

  return [errors, handleInputChange]
}
