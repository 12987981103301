import React, { useState } from 'react'
import { Paper, makeStyles } from '@material-ui/core'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import MapLegend from './MapLegend'
import COLORS from '../../assets/colors'

const useStyles = makeStyles({
  toggleButtonRoot: {
    background: '#eaeaea !important',
    color: `${COLORS.primary} !important`,
    '&:hover': {
      background: '#efefef !important',
    },
    border: 'none',
  },
  toggleButton: {
    color: `${COLORS.primary} !important`,
    background: 'white !important',
    borderBottom: 'none',
    borderBottomRightRadius: '0px',
    borderBottomLeftRadius: '0px',
    '&:hover': {
      background: '#efefef !important',
    },
  },
  paperStyleOpen: {
    borderTopRightRadius: '0px',
    borderTopLeftRadius: '0px',
    borderTop: 'none',
  },
})

const MapTableContainer = () => {
  const classes = useStyles()
  const [isOpen, toggleOpen] = useState(true)
  const handleClick = () => {
    toggleOpen(!isOpen)
  }

  const [alignment, setAlignment] = React.useState<string | null>('left')

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    setAlignment(newAlignment)
  }

  return (
    <>
      <ToggleButtonGroup
        value={alignment}
        exclusive
        onChange={handleAlignment}
        aria-label="text alignment"
      >
        <ToggleButton
          value="left"
          classes={{
            selected: classes.toggleButton,
            root: classes.toggleButtonRoot,
          }}
          size="small"
          aria-label="left aligned"
          onClick={handleClick}
        >
          Ränteskala {isOpen ? <ExpandLess /> : <ExpandMore />}
        </ToggleButton>
      </ToggleButtonGroup>
      {isOpen ? (
        <Paper elevation={0} className={isOpen ? classes.paperStyleOpen : ''}>
          <MapLegend />
        </Paper>
      ) : (
        <></>
      )}
    </>
  )
}

export default MapTableContainer
