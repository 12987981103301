import React, { useState, useEffect } from 'react'

import {
  makeStyles,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  TableHead,
} from '@material-ui/core'

import InformationTooltip from './InformationTooltip'
import staticScbData from '../../assets/staticScbData.json'
import { getScbData } from '../../api/getData'

import COLORS from '../../assets/colors'
import { translateBindingTimes } from '../../lib/translate'

interface ScbData {
  name: string
  value: string
}

interface RawSCBData {
  threeMonth: {
    values: string
  }
  oneYear: {
    values: string
  }
  threeYear: {
    values: string
  }
  fiveYear: {
    values: string
  }
}

interface DataProps {
  matchesSmAndUp: boolean
}

const useStyles = makeStyles({
  root: {
    background: 'white',
    color: COLORS.primary,
  },
  primaryText: {
    color: '#000',
  },
  tableCell: {
    paddingLeft: '5px',
    paddingRight: '5px',
    '&:first-child': {
      paddingLeft: '16px',
    },
  },
  tableHeaderCell: {
    paddingRight: '5px',
    paddingLeft: '5px',
    '&:first-child': {
      paddingLeft: '16px',
      fontWeight: 'normal',
    },
  },
  secondaryText: {
    color: COLORS.primary,
  },
  table: {
    background: COLORS.backgroundGrey,
  },

  tableWrapper: {
    position: 'relative',
  },
})

function ScbDataTable(props: DataProps) {
  const classes = useStyles()
  const { matchesSmAndUp } = props
  const [filteredData, setFilteredData] = useState<ScbData[]>()

  function parseData(data: RawSCBData) {
    const res: ScbData[] = [{ name: 'Bindningtid', value: 'Snitträntor' }]
    const scbDataEntries = Object.entries(data)

    for (let i = 0; i < scbDataEntries.length; i += 1) {
      res.push({
        name: translateBindingTimes(scbDataEntries[i][0]),
        value: `${Number(scbDataEntries[i][1].values).toFixed(2)} %`,
      })
    }

    return res
  }

  useEffect(() => {
    async function handleFetchScbData() {
      const res = await getScbData()
      const resultJSON = await res.json()
      if (resultJSON) {
        const parsedData = parseData(resultJSON)
        setFilteredData(parsedData)
      }
    }

    if (process.env.NODE_ENV === 'development') {
      console.log('Development env. Using static scb file')

      const parsedData = parseData(staticScbData)
      setFilteredData(parsedData)
    } else {
      console.log('Production env. Fetching scb data...')
      handleFetchScbData()
    }
  }, [])

  return (
    <Grid container>
      <Grid item xs={12} className={classes.tableWrapper}>
        {filteredData ? (
          <>
            <InformationTooltip
              title="Data från SCB över ränteläget i Sverige"
              placement="top-left"
            />
            <TableContainer>
              <Table
                className={classes.table}
                aria-label="simple table"
                size={matchesSmAndUp ? 'medium' : 'small'}
              >
                <TableHead>
                  <TableRow>
                    {filteredData.map((row) => (
                      <TableCell
                        classes={{ root: classes.tableHeaderCell }}
                        key={row.name}
                        align="left"
                      >
                        {row.name}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {filteredData.map((row) => (
                      <TableCell
                        classes={{ root: classes.tableCell }}
                        key={row.name}
                        align="left"
                      >
                        {row.value}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <div />
        )}
      </Grid>
    </Grid>
  )
}

export default ScbDataTable
