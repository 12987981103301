import { FormPayload } from '../form/Form'

// eslint-disable-next-line import/prefer-default-export
export const getHypoteketInterestRates = () =>
  process.env.NODE_ENV === 'development'
    ? fetch('http://localhost:5000/api/v1/loans/interestRates')
    : fetch('https://api.hypoteket.com/api/v1/loans/interestRates')

export const getHypoteketDynamicInterestRates = (userSentData: FormPayload) => {
  const { bindingTime, propertyValue, loanSize } = userSentData
  return process.env.NODE_ENV === 'development'
    ? fetch(
        `http://localhost:5000/api/v1/loans/interestRate?term=${bindingTime}&propertyValue=${propertyValue}&loanSize=${loanSize}`
      )
    : fetch(
        `https://api.hypoteket.com/api/v1/loans/interestRate?term=${bindingTime}&propertyValue=${propertyValue}&loanSize=${loanSize}`
      )
}
