import {
  CompareData,
  CompareDataEntry,
  InterestCompareData,
  InterestCount,
} from '../App'

const filterInterestPoints = (interestCounts: InterestCount) => {
  const INTEREST_COUNT_LIMIT = 5

  return (
    Object.values(interestCounts).reduce((acc, val) => acc + val, 0) >
    INTEREST_COUNT_LIMIT
  )
}

interface Interests {
  [key: string]: { [key: string]: number }
}

const filterInterests = (interests: InterestCompareData) => {
  return Object.entries(interests)
    .filter((interest) => filterInterestPoints(interest[1]))
    .reduce((acc: Interests, [bindingTime, interestCounts]) => {
      const accInterests = { ...acc }
      accInterests[bindingTime] = interestCounts
      return accInterests
    }, {})
}

// eslint-disable-next-line import/prefer-default-export
export const filterCompareData = (compareData: CompareData): CompareData => {
  const compareDataEntries = Object.entries(compareData)

  const filteredCompareDataEntries = compareDataEntries.map(
    ([areaLevel, areaData]): [string, CompareDataEntry] => {
      const newAreaData = {
        ...areaData,
        interest: filterInterests(areaData.interest),
      }

      return [areaLevel, newAreaData]
    }
  )

  const filteredCompareData: CompareData = {}

  for (let i = 0; i < filteredCompareDataEntries.length; i += 1) {
    const [areaLevel, newAreaData] = filteredCompareDataEntries[i]
    filteredCompareData[areaLevel] = newAreaData
  }

  return filteredCompareData
}
