import COLORS from '../../assets/colors'
import { CompareData } from '../../App'

const prependZero = (l: number, id: string) => {
  let newId = ''
  if (id.length === l) {
    newId = '0'.concat(id)
  } else {
    newId = id
  }
  return newId
}

function calculateAvgInterest(datapoint: any): number | undefined {
  if (!datapoint) return undefined
  const test = Object.entries(datapoint)

  let formula = 0
  let numberOfPoints = 0
  for (let i = 0; i < test.length; i += 1) {
    numberOfPoints += Number(test[i][1])
    formula += Number(test[i][0]) * Number(test[i][1])
  }

  return Number((formula / numberOfPoints).toFixed(2))
}

const addFillColor = (
  svgGroup: any,
  compareData: CompareData,
  bindingTime: string,
  paintLevel: string
) => {
  const svgChildren = svgGroup?.children
  // loop over each county
  for (let i = 0; i < svgChildren?.length; i += 1) {
    const county: SVGElement[] = svgChildren[i].children

    // loop over each municipality in each county
    for (let k = 0; k < county?.length; k += 1) {
      let fillColor = 'default'

      const idAttribute = county[k].getAttribute('id')
      let mId = ''
      if (idAttribute) {
        mId =
          paintLevel === 'counties'
            ? prependZero(3, idAttribute).slice(0, 2)
            : prependZero(3, idAttribute)
      }

      const postTownData = Object.entries(compareData).find(([key]) => {
        return key === mId
      })

      const theData = postTownData?.[1]
      let avgInt: number | undefined
      if (theData) {
        switch (bindingTime) {
          case 'threeMonth':
            avgInt = calculateAvgInterest(theData.interest.threeMonth)
            break
          case 'oneYear':
            avgInt = calculateAvgInterest(theData.interest.oneYear)
            break
          case 'threeYear':
            avgInt = calculateAvgInterest(theData.interest.threeYear)
            break
          case 'fiveYear':
            avgInt = calculateAvgInterest(theData.interest.fiveYear)
            break
          default:
            avgInt = undefined
        }
      }

      if (!avgInt) {
        fillColor = COLORS.noData
      } else if (avgInt <= 1.2) {
        fillColor = COLORS.low
      } else if (avgInt > 1.2 && avgInt <= 1.4) {
        fillColor = COLORS.lowMid
      } else if (avgInt > 1.4 && avgInt <= 1.6) {
        fillColor = COLORS.mid
      } else if (avgInt > 1.6 && avgInt <= 1.8) {
        fillColor = COLORS.highMid
      } else if (avgInt > 1.8) {
        fillColor = COLORS.high
      }

      if (county[k].tagName === 'g') {
        // some municipalities have groups of polygons
        for (let j = 0; j < county[k]?.children?.length; j += 1) {
          county[k].children[j].setAttributeNS(
            null,
            'style',
            `fill: ${fillColor}`
          )
        }
      } else {
        county[k].setAttributeNS(null, 'style', `fill: ${fillColor}`)
      }
    }
  }
}

export default addFillColor
